<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <Navigation />
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI智能PPT内容框架生成" />
                        <ai_select selectTitle="所属行业" :select_list="industry_list" @getSelectVal="get_industry" />
                        <ai_select selectTitle="PPT类型" :select_list="type_list" @getSelectVal="get_type" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">PPT主题：</p>
                            <div class="upload_box">
                                <el-input v-model="form.ppt_theme" placeholder="请录入您预制作的PPT主题"></el-input>
                            </div>
                        </div>
                        <button class="create_but" @click="FileData">生成内容</button>
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_select from '@/components/ai_select'
import ai_title from '@/components/ai_title'
import ai_text_content from '@/components/ai_text_content'
export default {
    mounted() { this.getUserInfo() },
    components: {
        ai_title,
        wordNav,
        ai_select,
        ai_text_content
    },
    data() {
        return {
            form: {
                industry: '',
                ppt_type: '',
                ppt_theme: '',
            },
            industry_list: [
                {
                    id: '科技',
                    name: '科技'
                },
                {
                    id: '教育',
                    name: '教育'
                },
                {
                    id: '党政党建',
                    name: '党政党建'
                },
                {
                    id: '餐饮美食',
                    name: '餐饮美食'
                },
                {
                    id: '金融理财',
                    name: '金融理财'
                },
                {
                    id: '体育运动',
                    name: '体育运动'
                },
                {
                    id: '家具装修',
                    name: '家具装修'
                },
                {
                    id: '旅游旅行',
                    name: '旅游旅行'
                },
                {
                    id: '医学医疗',
                    name: '医学医疗'
                },
                {
                    id: '汽车交通',
                    name: '汽车交通'
                },
                {
                    id: '工业',
                    name: '工业'
                },
                {
                    id: '美容',
                    name: '美容'
                },
                {
                    id: '房地产',
                    name: '房地产'
                },
            ],
            fullscreenLoading: false,
            contentData: {}, //内容
            type_list: ['工作总结', '工作汇报', '工作计划', '商业融资', '毕业答辩', '述职报告', '个人简介', '自我介绍', '公司简介', '培训课件', '培训课件', '绘本故事'],
            aiNumShow: false,
            aiPoupShow: false,

        }
    },
    methods: {
        get_industry(val) {
            this.form.industry = val
        },
        get_type(val) {
            this.form.ppt_type = val
        },
        // 获取用户信息
        getUserInfo() {
            this.curlGet('/api/user/info').then((res) => {
                if (res.data.code) {
                    this.$user_info = res.data.data
                    this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                }
            })
        },
        FileData() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.business == '') {
            //     return this.$message({
            //         message: '请输入标题',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80,
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }

        },
        postData() {
            this.aiNumShow = false
            this.fullscreenLoading = true
            this.curlPost('/file/create_ppt', {
                industry: this.form.industry,
                ppt_type: this.form.ppt_type,
                ppt_theme:this.form.ppt_theme,
                mobile: this.$user_info.mobile,
            }).then(res => {
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()

                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
                this.fullscreenLoading = false
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai'
</style>